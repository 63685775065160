import React, { useState,useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import "../css/AgvtingDetails.css";
import $ from "jquery";
import { useTranslation, Trans } from 'react-i18next'; 
import TopMenu from "./TopMenu";
import { ethers } from 'ethers';
import Web3 from 'web3';


import * as securetyAs from './securityUtill';
import * as blockDataUtill from './blockDataUtill'; 

const AgvtingDetails = ({handle}) => {
  const locationParam = useLocation();
  const [address, setAddress] = useState('');
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [votingType, setVotingType] = useState(0);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [agreeCount, setAgreeCount] = useState(0);
  const [agreePer, setAgreePer] = useState(0);
  const [disagreePer, setDisagreePer] = useState(0);
  const [vKonetBalOf, setVKonetBalOf] = useState("0");
  const [regDate, setRegDate] = useState("0");
  
  
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
 
  const [startDateTxt, setStartDateTxt] = useState("");
  const [endDateTxt, setEndDateTxt] = useState("");
  
  const [disagreeCount, setDisagreeCount] = useState(0);
  const [totCount, setTotCount] = useState(0);
  const [totCountForm, setTotCountForm] = useState(0);

  const [minimumbVotes, setMinimumbVotes] = useState("");
  
  const [totKonetCount, setTotKonetCount] = useState("");
  
  const [myAgreeCount, setMyAgreeCount] = useState("");
  const [myDisagreeCount, setMyDisagreeCount] = useState("");

  const [totMyVotings, setTotMyVotings] = useState("");
  const [totMyVotingsNum, setTotMyVotingsNum] = useState("");

  const [btnTxtReturn, setBtnTxtReturn] = useState("");
  

  //
  //getMyGovernence
  //
  const fullDataForm = (date) => {
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();

    month = month >= 10 ? month : '0' + month;
    day = day >= 10 ? day : '0' + day;
    hour = hour >= 10 ? hour : '0' + hour;
    minute = minute >= 10 ? minute : '0' + minute;
    second = second >= 10 ? second : '0' + second;

    const fullYear = date.getFullYear(); // 예: 2024


    return fullYear +"" + month + "" + day;
  }

  const fullTwoDataForm = (date) => {
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();

    month = month >= 10 ? month : '0' + month;
    day = day >= 10 ? day : '0' + day;
    hour = hour >= 10 ? hour : '0' + hour;
    minute = minute >= 10 ? minute : '0' + minute;
    second = second >= 10 ? second : '0' + second;

    const fullYear = date.getFullYear(); // 예: 2024


    return fullYear +"-" + month + "-" + day;
  }

  const fullTwoDataMinForm = (date) => {
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();

    month = month >= 10 ? month : '0' + month;
    day = day >= 10 ? day : '0' + day;
    hour = hour >= 10 ? hour : '0' + hour;
    minute = minute >= 10 ? minute : '0' + minute;
    second = second >= 10 ? second : '0' + second;

    const fullYear = date.getFullYear(); // 예: 2024


    return fullYear +"-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
  }

  const fullTwoDataMinUnForm = (date) => {
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();

    month = month >= 10 ? month : '0' + month;
    day = day >= 10 ? day : '0' + day;
    hour = hour >= 10 ? hour : '0' + hour;
    minute = minute >= 10 ? minute : '0' + minute;
    second = second >= 10 ? second : '0' + second;

    const fullYear = date.getFullYear(); // 예: 2024


    return fullYear +"" + month + "" + day + "" + hour + "" + minute + "" + second;
  }

  const [modal1, setModal1] = useState({
      onTtl : null
  ,   onCloseBool : null
  ,   onContentTxt : ""
  });

  const votingCalls = async () => {
    let callsBoolen = false;

    if( votingType == 1){
      callsBoolen = true;

    }else{
      callsBoolen = false;
      
    }
    const vals = $( "#votingCtn").val();
    if( vals == "" || vals == null || vals == undefined){
      alert('Please enter the balance to send.');
      return;
    }

    if( votingType == 0){
      alert('Please select the type to send.');
      return;
    }
    if(locationParam.state.indexParam === ""){
      alert('System error. Please try again. Error code: index none.');
      return;
    }

    if( totMyVotingsNum < Number(vals)){
      alert('The amount you requested is greater than the number of voting rights I have.');
      return;
    }

    const vKonetVal = await blockDataUtill.getvKonetBal( address);
    const vKonetbalance = await blockDataUtill.balanceOfFomatTwo( vKonetVal);

    if( Number(vKonetbalance) < Number(vals)){
      alert('Insufficient vkonet balance for voting.');
      return;
    }

    console.log(locationParam.state.indexParam, callsBoolen, vals);


    await blockDataUtill.insertVote( locationParam.state.indexParam, callsBoolen, vals);
    window.location.reload();


  }

  const handleMessageFromChild = (newAddress) => {
    setAddress(newAddress);  // 자식에서 받은 메시지로 상태 업데이트
  };

  const votingBtns = ( _obj) => {
      $( ".votingBtnClass").removeClass( "votingActive");
      $( "#" + _obj).addClass( "votingActive");
      setVotingType(0);
      //agreeBtn disagreeBtn
      if( _obj == "agreeBtn"){
        setVotingType(1);
      }
      if( _obj == "disagreeBtn"){
        setVotingType(2);
      }
      console.log(votingType);
      
  }

  const initView = async ( address) => {
    const listNum = locationParam.state.indexParam;
    const listreturn = await blockDataUtill.getGovernanceListInfo( listNum);
    const detailesAddress = listreturn[0];
    const detailInfo = await blockDataUtill.getInfoAll(detailesAddress);
    
    let _agreeCount = await blockDataUtill.balanceOfFomatTwo( listreturn[5]);
    let _disagreeCount = await blockDataUtill.balanceOfFomatTwo( listreturn[6]);
    
    console.log( "??????????????");
    console.log(_agreeCount);
    console.log(_disagreeCount);
    console.log( "??????????????");
    

    let _totCount = Number(_agreeCount) + Number( _disagreeCount);

    setTitle( detailInfo[0]);
    setContent( detailInfo[1]);
    setAgreeCount( _agreeCount);
    setDisagreeCount( _disagreeCount);


    setTotCount( _totCount);
    setTotCountForm(_totCount);

  
    if( _totCount <= 0){
      setAgreePer( 0);
      setDisagreePer( 0);
    }else{
      const agreePercentage = ( _agreeCount / _totCount) * 100;
      const disagreePercentage = ( _disagreeCount / _totCount) * 100;

      setAgreePer( agreePercentage);
      setDisagreePer( disagreePercentage);
    }

    //현재 vkonet 갯수
    const vKonetVal = await blockDataUtill.getvKonetBal( address);
    const vKonetbalance = await blockDataUtill.balanceOfFomat( vKonetVal);


    setVKonetBalOf( vKonetbalance);

    const startdate = new Date(Number( detailInfo[5]) * 1000); 
    const startfullData = fullTwoDataMinForm( startdate);

    setStartDateTxt(fullTwoDataForm(startdate));


    
    const enddate = new Date(Number( detailInfo[6]) * 1000); 
    const endfullData = fullTwoDataMinForm( enddate);

    setEndDateTxt(fullTwoDataForm(enddate));

    const regdate = new Date(Number( detailInfo[3]) * 1000); 
    const regdateFullData = fullTwoDataMinForm( regdate);
    setRegDate( regdateFullData);


    setStartDate(startfullData);
    setEndDate(endfullData);
    
    console.log( ethers.formatEther(listreturn[3]));
    console.log(listreturn[3]);

    const balance = await blockDataUtill.balanceOfFomat( listreturn[3]);    
    const balanceC = await blockDataUtill.balanceOfFomatTwo( listreturn[3]);

    const balaceNa = Number(balanceC);
    const thirtyPercentValue = balaceNa * ( Number(listreturn[4]) / 100);




    setMinimumbVotes( thirtyPercentValue.toFixed(4));
    setTotKonetCount( balance);

    const myGovernence = await blockDataUtill.getMyGovernence( address , listNum);


    let myagreeNum = await blockDataUtill.balanceOfFomatTwo( myGovernence[0]);
    let mydisagreeCount = await blockDataUtill.balanceOfFomatTwo( myGovernence[1]);
    let summyVoting = Number( myagreeNum) +  Number( mydisagreeCount);
    const vKonetbalanceForm = await blockDataUtill.balanceOfFomatTwo( vKonetVal);
    let totmyVoting = Number( vKonetbalanceForm) - summyVoting;
    setTotMyVotingsNum( totmyVoting);
    totmyVoting = await blockDataUtill.balanceOfFomatTh( totmyVoting);
    setTotMyVotings( totmyVoting);


    setMyAgreeCount( await blockDataUtill.balanceOfFomat( myGovernence[0]));
    setMyDisagreeCount( await blockDataUtill.balanceOfFomat( myGovernence[1]));

    


    //writeView
    const nowdate = new Date(Number( detailInfo[8]) * 1000); 
    const nowfullData = Number(fullTwoDataMinUnForm( nowdate));

    const startOriGe = new Date(Number( detailInfo[5]) * 1000); 
    const startfullNum = Number(fullTwoDataMinUnForm( startOriGe));

    const endOriGe = new Date(Number( detailInfo[6]) * 1000); 
    const endfullNum = Number( fullTwoDataMinUnForm( endOriGe));
    

    if( nowfullData >= startfullNum &&  nowfullData <= endfullNum){
      $( ".writeView").show();
      $( "#noneVotingDate").hide();

    }
    
    let btnTxtReturn = "Failed"; //통과실패

    if( nowfullData < startfullNum){
      btnTxtReturn = "Registration";
    }
    


    if( nowfullData >= startfullNum &&  nowfullData <= endfullNum){
      btnTxtReturn = "Voting Start";
    }

    
    if(endfullNum < nowfullData && (thirtyPercentValue <= _totCount) && (_agreeCount > _disagreeCount)){
      btnTxtReturn = "Passed";
    }

    setBtnTxtReturn(btnTxtReturn);



  }

  useEffect(() => {

      return () => {
          console.log('컴포넌트가 언마운트될 때 실행됩니다.');
      };
  }, []); 
  useEffect(() => {
    if( address != null && address != ""){
      initView( address);
    }
    


    return () => {
        console.log('컴포넌트가 언마운트될 때 실행됩니다.');
    };
  }, [address]); 


  return (
    <div className="HomeLst">
        <TopMenu menuType={2} handle={handleMessageFromChild} />
         
        <div className='maxWidthRap meMarHei'>
          <div className='meTpTtl fc_000000' style={{textAlign : "center"}}>{title}</div>
          <div className='votingDateCall fc_000000'>
              <div className='votingDateLt'>
                  <div className='MyOldCnt bg_blue_FFFFFF'>vKonet owned<br />{vKonetBalOf} vKonet</div>
                  <div style={{clear : "both"}}></div>
              </div> 
              <div className='votingDateRt'>
                  <div className='votingContentBtn fc_000000'>{btnTxtReturn}</div>
                  <div style={{clear : "both"}}></div>
                  <div className='votingContentDate fc_000000'>{startDateTxt} ~ {endDateTxt}</div>
                  <div style={{clear : "both"}}></div>
              </div>
              
              <div style={{clear : "both"}}></div>

              <div className='votingRageRap bg_blue_FFFFFF'>
              <div className='totVotingCnt fc_000000'>Total voting {totCountForm.toFixed(4)} vKonet</div>
                  <div className='votingRageTtl fc_000000' >Agree</div>
                  <div className='votingRageSub'> 
                    <div className='votingRageLt fc_000000'>{agreeCount} vKonet</div>
                    <div className='votingPer'>{agreePer.toFixed(2) + "%"}</div>
                    <div style={{clear : "both"}}></div>
                  </div>
                  <div className='progressTpMar'>  
                  <progress className='greenprogress' value={agreeCount} max={totCount}></progress>

                  </div>
              </div>
              <div className='votingRageRap bg_blue_FFFFFF' style={{marginTop : "10px"}}>
                  <div className='votingRageTtl fc_000000'>Disagree</div>
                  <div className='votingRageSub'>
                    <div className='votingRageLt fc_000000'>{disagreeCount} vKonet</div>
                    <div className='votingPer'>{disagreePer.toFixed(2) + "%"}</div>
                    <div style={{clear : "both"}}></div>
                  </div>
                  <div className='progressTpMar'>
                  <progress className='ledprogress' value={disagreeCount} max={totCount}></progress>

                  </div>
              </div>

              <div className='votingRageRap bg_blue_FFFFFF' style={{marginTop : "10px"}}>
                <div className='votingRageSubTtl'>{title}</div>
                <div className='votingRageSubContent'>{content}</div>
                
                <div className='botingBtLine bg_blue_5B6BAF'></div>

                <div className='botingContentRap'>
                    {/* 발행된 총 vkonet */}
                    <div className='botingContentLiRap'>
                      <div className='botingContentLiLt'>Total issued vkonet</div>
                      <div className='botingContentLiRt'>{totKonetCount} vkonet</div>
                      <div style={{clear : "both"}}></div>
                    </div>

                    {/* 의제 통과 최소 투표권 */}
                    <div className='botingContentLiRap botingTpMar'>
                      <div className='botingContentLiLt'>Minimum votes to pass</div>
                      <div className='botingContentLiRt'>{minimumbVotes} vkonet</div>
                      <div style={{clear : "both"}}></div>
                    </div>

                    {/* 현재 투표된 투표권 */}
                    <div className='botingContentLiRap botingTpMar'>
                      <div className='botingContentLiLt'>Current votes</div>
                      <div className='botingContentLiRt'>{totCount.toFixed(4)} vkonet</div>
                      <div style={{clear : "both"}}></div>
                    </div>

                </div>

                <div className='botingBtLine bg_blue_5B6BAF'></div>
                
                <div className='botingContentRap'>
                  {/*  의제 등록일시 */}
                    <div className='botingContentLiRap'>
                      <div className='botingContentLiLt'>Agenda time</div>
                      <div className='botingContentLiRt'>
                          {regDate}
                      </div>
                      <div style={{clear : "both"}}></div>
                    </div>

                  {/*  의제 투표 시작 날짜 */}
                    <div className='botingContentLiRap botingTpMar'>
                      <div className='botingContentLiLt'>Vote start date</div>
                      <div className='botingContentLiRt'>
                          {startDate}
                      </div>
                      <div style={{clear : "both"}}></div>
                    </div>

                  {/*  의제 투표 마감 날짜 */}
                    <div className='botingContentLiRap botingTpMar'>
                      <div className='botingContentLiLt'>Vote end date</div>
                      <div className='botingContentLiRt'>
                        {endDate}
                      </div>
                      <div style={{clear : "both"}}></div>
                    </div>
                    
            
                </div>

                <div className='botingBtLine bg_blue_5B6BAF'></div>
                
                  <div className='votingViewRap'>
                    <div className='writeView' style={{display : "none"}}>
                      <div className='votingBtnRap'>
                        <div className='votingBtnLt votingBtnClass' onClick={(e)=>{votingBtns( "agreeBtn")}} id="agreeBtn">agree</div>
                        <div className='votingBtnRt votingBtnClass' onClick={(e)=>{votingBtns( "disagreeBtn")}} id='disagreeBtn'>disagree</div>
                        <div style={{clear : "both"}}></div>
                      </div>
                      <div className='votingInputRap'>
                        <input type="number" id='votingCtn' />
                      </div>
                    </div>
                    <div className='votingInputTxt'>vKonet {vKonetBalOf} owned</div>
                    <div className='votingInputTxt'>My vote Agree {myAgreeCount} vkonet</div>
                    <div className='votingInputTxt'>My vote Disagree {myDisagreeCount} vkonet</div>
                    <div className='votingInputTxt'>My vote is possible {totMyVotings} vkonet</div>

                    
                    
                    
                  </div>
                  {/*  투표하기 */}
                  <div className='writeView' style={{display : "none"}}>
                    <div className='stakingButton bg_blue_C4CFFF fc_0029E2' onClick={(e)=>{votingCalls( )}}>vote</div>
                  </div>
                  
                  <div className='noneVotingDate' id='noneVotingDate'>
                    It is not the voting period.
                  </div>
                
                

              </div>


          </div>

         


        </div>

        <div className='bottomHeiRap'></div>

    </div>
  );
};

export default AgvtingDetails;