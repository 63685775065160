import CryptoJS from 'crypto-js';
import React, { useState } from 'react';
import { generateMnemonic } from 'bip39';
/* global BigInt */

const SECRET_KEY = '3c0c11b05b7d6bfc3f5d707bbfa2d451c9f5d6c8997759d3e59cc632c17b8d93'; // 비밀 키 설정

export const addressSimply = (address) =>{
    // 주소 길이가 10자 이하일 경우, '...' 없이 그대로 반환
    if (address.length <= 10) {
      return address;
    }
  
    // 앞 5글자 + '...' + 뒤 5글자
    const firstFive = address.slice(0, 5);
    const lastFive = address.slice(-5);
    return `${firstFive}...${lastFive}`;
}
  



export const tokenBalReturn = (tokenBal) =>{
    
   if( tokenBal == 0 || tokenBal == "" || tokenBal == undefined){
        return "0";
   }
  
  //const formattedStr = tokenBal.toLocaleString('fullwide', { maximumFractionDigits: 0 });
  const formattedStr = tokenBal.toLocaleString('fullwide', { useGrouping: false });
  
  const balTots = "" + formattedStr;
  var totReturtn = null;
  
  let integerPart;
  let decimalPart;
  //

  if( formattedStr.length < 18){

    
    const balCount = 18 - formattedStr.length;
    const totBal = ""+ formattedStr;
    

    const returnTotBal = '0'.repeat(balCount) + totBal;
    totReturtn = returnTotBal;
   
  }else if(formattedStr.length == 18){

    let totBal = "0"+ formattedStr.toString()+"";
    totReturtn = totBal;
  }
  
  if( totReturtn == null){
    totReturtn = balTots;
    
  }
  //1321110000000000000000  1321.110000000000000000
   integerPart = (totReturtn).slice(0, -18); // 소수점 앞부분 (123)
   
   decimalPart = (totReturtn).slice(-18);    // 소수점 뒷부분 (4567)
  
   decimalPart = (decimalPart).slice(0, 5);
   if( integerPart == ""){
    integerPart = "0";
   }


  const dataStr = integerPart +"."+ decimalPart;
  // 정수부와 소수부를 합쳐 소수점 추가
  return dataStr;
  
}



// 데이터 암호화 함수
export const encryptData = (data, _SECRET_KEY) => {
    try {
        return CryptoJS.AES.encrypt(JSON.stringify(data), _SECRET_KEY).toString();
    } catch (error) {
        console.error("암호화 오류:", error);
        return null;
    }
};

// 데이터 복호화 함수
export const decryptData = (encryptedData, _SECRET_KEY) => {
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, _SECRET_KEY);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        
        // 복호화된 데이터가 유효한지 체크
        if (!decryptedData) {
            throw new Error("복호화된 데이터가 유효하지 않습니다.");
        }

        return JSON.parse(decryptedData);
    } catch (error) {
        console.error("복호화 오류:", error);
        return null; // 오류 발생 시 null 반환
    }
};



// 데이터 저장 함수
export const setEncryptedItem = (key,_SECRET_KEY, data) => {
    const encryptedData = encryptData(data, _SECRET_KEY);
     if (encryptedData) {
         localStorage.setItem("timeminAddressInfo", encryptedData);
     } else {
         console.error("데이터를 저장할 수 없습니다.");
     }
};

// 데이터 가져오기 함수
export const getEncryptedItem = (key, _SECRET_KEY) => {
    const encryptedData = localStorage.getItem( "timeminAddressInfo");
    console.log("데이터 가져오기 시도:", encryptedData);

    if (encryptedData) {
        const result = decryptData(encryptedData, _SECRET_KEY);
        //console.log("복호화된 데이터:", result);
        return result;
    } else {
        console.warn("해당 키에 대한 데이터가 없습니다.");
        return null;
    }
};

//로컬스토리지 모두 삭제
export const deleteLocalAll = () => {
    localStorage.clear();
};



//timeminAddressInfo
export const getNoEncryptedItem = () => {
    const encryptedData = localStorage.getItem( "timeminAddressInfo");
    return encryptedData;

};





// 데이터 저장 함수
export const setSessionEncryptedItem = (_data) => {

    sessionStorage.setItem('conetAddress', JSON.stringify(_data));
};


// 데이터 저장 함수
export const getSessionEncryptedItem = () => {
    let  sessionStge= JSON.parse(sessionStorage.getItem('conetAddress'));
   
    
    return sessionStge;

};


//로컬스토리지 모두 삭제 
export const deleteSessionAll = () => {
    sessionStorage.clear();
};



