import React, { useState,useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import './i18n';
import "./css/ColorStyles.css";
import "./css/BackgroundColor.css";



import { useLocation, useNavigationType ,Routes, Route } from "react-router-dom";



import Home from "./pages/Home";
import Agvting from "./pages/Agvting";
import AgvtingDetails from "./pages/AgvtingDetails";
import AgvtingIns from "./pages/AgvtingIns";





import { CSSTransition, TransitionGroup } from 'react-transition-group';


const langsType = process.env.REACT_APP_API_LANG;


function App() {

  const location = useLocation();
  const navigationType = useNavigationType();
 



  return (
      <div>
        <div className="lodingBarBack"></div>
        <div className="lodingBar">
          <img src={process.env.PUBLIC_URL + '/imgaes/lodingGif.gif'} />
        </div>
        
        <Routes location={location}>
          <Route path="/" element={<Home />} />
          <Route path="/Agvting" element={<Agvting />} />
          <Route path="/AgvtingDetails" element={<AgvtingDetails />} />
          <Route path="/AgvtingIns" element={<AgvtingIns />} />
          
        </Routes>


        <div className='bottomVersion'>Web version {process.env.REACT_APP_API_VER}</div>
      </div>
  );
}

export default App;
