import React, { useState,useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import "../css/TopMenu.css";
import $ from "jquery";
import { useTranslation, Trans } from 'react-i18next'; 
import { ethers } from 'ethers';
import Web3 from 'web3';

import * as securetyAs from './securityUtill'; 
import * as blockDataUtill from './blockDataUtill'; 


const TopMenu  = ({ menuType, handle}) => {
  const [ignored, setIgnored] = useState(0);
  const navigate = useNavigate();
  const [isMetaMaskInstalled, setIsMetaMaskInstalled] = useState(false); //메타마스크 관련
  const [account, setAccount] = useState(""); //메타마스크 관련


  const { t, i18n } = useTranslation();
  const [checkSubMenu, setCheckSubMenu] = useState(false);
  const [c_addressSimpl, setC_addressSimpl] = useState( "");
  const [konetBalOf, setKonetBalOf] = useState("0");
  
  const initView = async (userAccount) => {
    const konetBalanceOf = await blockDataUtill.konetBalanceOf( userAccount);
    setKonetBalOf( konetBalanceOf);
  }

  const handleResize = () => {
    // 추가 로직 수행
    $( ".subMenuDivRap").hide();
    setCheckSubMenu(false);

    const mediaQuery = window.matchMedia("(max-width: 1400px)");
    if (mediaQuery.matches) {
      $( "#okayLogin").hide();
      $( "#notLogin").hide();
    } else {
      $( "#okayLogin").hide();
      $( "#notLogin").hide();
      if( account != null && account != ""){
        $( "#okayLogin").show();
      }else{
        $( "#notLogin").show();
      }

    }




  };
  const subMenu = (e) => {

    if( checkSubMenu == false){
      $( ".subMenuDivRap").show();
      setCheckSubMenu(true);
      return;
    }
    
    $( ".subMenuDivRap").hide();
    setCheckSubMenu(false);

  }

  const connectMetaMask = async () => {

    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);
      const checkId = await blockDataUtill.chainIdCheck();
      if(checkId != process.env.REACT_APP_API_CHAIN_ID){
        alert('The network is different. ' + checkId);
        return;

      }
      
      try {
            // MetaMask 계정 요청 창 띄우기
             window.ethereum.request({ method: 'eth_requestAccounts' })
             .then((accounts) => {
                setAccount(accounts[0]);
                connectWallet( accounts);
             })
             .catch((error) => {
               if( error.code === -32002){
                  alert('메타마스크창에서 잠금을 풀어주세요.');
               }
             });

        //setAccount(accounts[0]);
      } catch (error) {
        console.error(error);
      }
    } else {
      alert('Please install MetaMask!');
    }
  };




  useEffect(() => {


      $( ".subMenuLi").removeClass( "subMenuActive");
      $( ".subMenuLi").eq( (menuType-1)).addClass( "subMenuActive");


      // 메타마스크 설치 여부 확인
      if (window.ethereum) {
        window.ethereum.on("accountsChanged", (accounts) => {

          window.location.reload();
      
        });
      
        window.ethereum.on("chainChanged", () => {
    
          window.location.reload();
        });  

        checkMetaId();

        setIsMetaMaskInstalled(true);
        
        // 메타마스크 연결된 계정 확인
        window.ethereum.request({ method: 'eth_accounts' })
          .then(accounts => {
            console.log("확인점" , accounts[0]);
            if (accounts.length > 0) {

              setAccount( accounts[0]);
              connectWallet( accounts);
              
              return;
            }
            
          })
          .catch((error) => {
            console.error('Error fetching accounts:', error);
          });

      } else {
        setIsMetaMaskInstalled(false);
      }

      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };

  }, []); 

  useEffect(() => {

  


  }, [ account]); 


  const checkMetaId = async () => {
    const checkId = await blockDataUtill.chainIdCheck();
    if(checkId != process.env.REACT_APP_API_CHAIN_ID){
      $( ".netWorkAddCehck").show();

    }
  }

  const callback = (address) => {
    handle(address);
  }


  const connectWallet = ( _account) => {
    

    if(_account.length > 0){
        const addressSimply = securetyAs.addressSimply(_account[0]);
        setC_addressSimpl( addressSimply);
        callback( _account[0]);
        initView( _account[0]);

        const mediaQuery = window.matchMedia("(max-width: 1400px)");
        if (mediaQuery.matches) {
          $( "#okayLogin").hide();
          $( "#notLogin").hide();
        } else {
          $( "#okayLogin").show();
          $( "#notLogin").hide();

        }


    }
  }
  const chainFun = async () => {
    const chainId = process.env.REACT_APP_API_CHAIN_ID; // Ropsten 테스트넷의 Chain ID는 '0x3'

    try{
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId }],
      });
   
    }catch(error){
      if (error.code === 4902) {
        // 해당 네트워크가 MetaMask에 없으면, 네트워크를 추가해야 한다.
        // const addNetworkParams = {
        //   chainId: chainId, // Ropsten의 Chain ID
        //   chainName: 'KONET',
        //   rpcUrls: [ process.env.REACT_APP_API_CHAIN_URL],
        //   nativeCurrency: {
        //     name: 'KONET',
        //     symbol: 'KONET',
        //     decimals: 18,
        //   },
        //   blockExplorerUrls: ['https://explorer.kon-wallet.com'],
        // };

        const addNetworkParams = {
          chainId: chainId, // Ropsten의 Chain ID
          chainName: 'KONET',
          rpcUrls: [ process.env.REACT_APP_API_CHAIN_URL],
          nativeCurrency: {
            name: 'KONET',
            symbol: 'KONET',
            decimals: 18,
          },
          blockExplorerUrls: [process.env.REACT_APP_API_CHAIN_URLEx],
        };
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [addNetworkParams],
          });
        } catch (addError) {
          console.error('Failed to add network', addError);
        }
      } else {
        console.error('Error switching network', error);
      }  
    }

  }

  const chaingeMeta = () => {
    chainFun();
  }

  const clickLocation = ( _url) => {

    navigate( "/" + _url);

  }
  
  const loginPop = () => {
    if( account != null && account != ""){
      $( "#layPopAddress").show();

    }else{
      connectMetaMask();
      
    }

  
  }

  const popUrlGo = () =>{
    //
    window.open('https://konetexplorer.io/', '_blank');
  }
  const closeMobile = () => {
    $( "#layPopAddress").hide();

  }

  return (
    <div className="topMenuDivRap">
        <div className='topMenuMarHei'></div>
        <div className='subMenuDivRap bg_blue_FFFFFF'>
          <div className='subMenuRap'>
            <div className='subMenuLi ' onClick={(e)=>{clickLocation( "")}}>Konet Staking</div>
            <div className='subMenuLi ' onClick={(e)=>{clickLocation( "Agvting")}}>Agenda voting</div>
            
            <div style={{clear : "both"}}></div>
          </div>
        </div>
        {/* <div className='netWorkAddCehckBack'></div> */}
        <div className='netWorkAddCehck ' onClick={(e)=>{chaingeMeta()}}>Wrong Network</div>    
        <div className='topMenuRap bg_blue_FFFFFF'>
          <div className='topmenuTotRap'>
            <div className='topMenuLtRap'>

                <div className='topMenuLtLogo'>
                  <img src={process.env.PUBLIC_URL + "/imgaes/logo_konet_header.png"} onClick={(e)=>{clickLocation( "")}} />
                </div>
                <div className='topMenuLtMobileRap'>
                  <div className='topMenuLtTxt fc_000000 txtLtMar' onClick={(e)=>{clickLocation( "")}}>Konet Staking</div>
                  <div className='topMenuLtTxt fc_000000 txtLtTwoMar' onClick={(e)=>{clickLocation( "Agvting")}}>Agenda voting</div>

                  <div style={{clear : "both"}}></div>
                </div>
                
            
            
                <div style={{clear : "both"}}></div>
            </div>
            <div className='topMenuRtRap'>
                <div className='layPopAddress' id="layPopAddress">
                  <div className='connetWalletButtonPop bg_black_1E293B fc_FFFFFF'>{c_addressSimpl}</div>
                  <div className='closeName' onClick={(e)=>{closeMobile( )}}>close</div>
                </div>
                <div className='topMenuLtTxt mobileEx fc_000000 txtLtTwoMar' onClick={(e)=>{popUrlGo( "")}}>Explore</div>
                <div className='mobileWalletIcon'onClick={(e)=>{loginPop( )}}>
                  <img src={process.env.PUBLIC_URL + "/imgaes/ic_wallet_stroke_black.png"} />
                </div>
                <div className='walletInfoButton'  onClick={(e)=>{subMenu(e)}}>
                  <div className='walletInfoLi bg_black_000000'></div>
                  <div className='walletInfoLi bg_black_000000'></div>
                  <div className='walletInfoLi bg_black_000000'></div>
                </div>
                

                {/* 로그인 */}
                <div className='topMenuRtIconTxtTotLoginRap' id='okayLogin'>
                  <div className='topMenuRtIconTxtRap txtLtTwoMar'>
                    <div className='topMenuRtIcon'>
                      <img src={process.env.PUBLIC_URL + "/imgaes/ic_wallet_stroke_black.png"} />
                    </div>
                    <div className='topMenuRtIconTxt fc_000000'>{konetBalOf} KONET</div>
                    <div style={{clear : "both"}}></div>
                  </div>
                  {/* <div className='topMenuRtIconTxtRap txtLtTwoMar'>
                    <div className='topMenuRtIcon'>
                      <img src={process.env.PUBLIC_URL + "/imgaes/topWonIcon.png"} />
                    </div>
                    <div className='topMenuRtIconTxt fc_FFFFFF'>123,456.1234 sKONET</div>
                    <div style={{clear : "both"}}></div>
                  </div> */}
                    <div className='connetWalletButton bg_black_1E293B fc_FFFFFF'>{c_addressSimpl}</div>
                  <div style={{clear : "both"}}></div>
                </div>
                {/* 로그인 끝 */}

                {/* 비로그인 */}
                <div className='topMenuRtIconTxtTotNotLoginRap' id='notLogin'>
                  <div className='connetWalletButton bg_black_1E293B fc_FFFFFF' onClick={(e)=>{connectMetaMask()}}>Connect Wallet</div>

                  <div style={{clear : "both"}}></div>
                </div>
                {/* 비로그인 끝 */}


                <div style={{clear : "both"}}></div>
            </div>


            <div style={{clear: "both"}}></div>
          </div>
          
        </div>
   
    </div>
  );
};

export default TopMenu;