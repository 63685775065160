import React, { useState,useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import "../css/Home.css";
import $ from "jquery";
import { useTranslation, Trans } from 'react-i18next'; 
import TopMenu from "./TopMenu";
import { ethers } from 'ethers';
import Web3 from 'web3';


import * as securetyAs from './securityUtill';
import * as blockDataUtill from './blockDataUtill'; 

const Home = ({handle}) => {
  const [address, setAddress] = useState('');
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loginCheck, setLoginCheck] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [konetBalOf, setKonetBalOf] = useState("0");
  const [statkingBalOf, setStatkingBalOf] = useState("0");
  const [vKonetBalOf, setVKonetBalOf] = useState("0");
  const [stakingFeeCal, setStakingFeeCal] = useState("0");
  const [stakingFinalDate, setStakingFinalDate] = useState("");
  

  const [modal1, setModal1] = useState({
      onTtl : null
  ,   onCloseBool : null
  ,   onContentTxt : ""
  });


  const fullTwoDataMinUnForm = (date) => {
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();

    month = month >= 10 ? month : '0' + month;
    day = day >= 10 ? day : '0' + day;
    hour = hour >= 10 ? hour : '0' + hour;
    minute = minute >= 10 ? minute : '0' + minute;
    second = second >= 10 ? second : '0' + second;

    const fullYear = date.getFullYear(); // 예: 2024


    return fullYear +"" + month + "" + day + "" + hour + "" + minute + "" + second;
  }

  const dataForm = (date) => {
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();

    month = month >= 10 ? month : '0' + month;
    day = day >= 10 ? day : '0' + day;
    hour = hour >= 10 ? hour : '0' + hour;
    minute = minute >= 10 ? minute : '0' + minute;
    second = second >= 10 ? second : '0' + second;

    return date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
  }
  


  const initView = async (userAccount) => {
    //내가 가지고 있는 코넷 갯수
    const konetBalanceOf = await blockDataUtill.konetBalanceOf( userAccount);
    setKonetBalOf( konetBalanceOf);

    //스테이킹한 코넷 갯수
    const memberInfoVal = await blockDataUtill.getStakingBal( userAccount);

    const stakingFeeCal = await blockDataUtill.getFeeCal( memberInfoVal);
    
    setStakingFeeCal( Number( stakingFeeCal));


    const balance = await blockDataUtill.balanceOfFomat( memberInfoVal);
   

    setStatkingBalOf(balance);

    //현재 vkonet 갯수
    const vKonetVal = await blockDataUtill.getvKonetBal( userAccount);
    const vKonetbalance = await blockDataUtill.balanceOfFomat( vKonetVal);

    setVKonetBalOf( vKonetbalance);

    const memberInfo = await blockDataUtill.getMemberInfo( userAccount);
    if(memberInfo != null){
      const date = new Date(Number( memberInfo[4]) * 1000); 
      const formattedDate = dataForm( date);
      setStakingFinalDate( formattedDate);
    }
 

    


  }
  
  const stakingCall = async () => {
    const stakingBalval = $( "#stakingInp").val();
    //konetBal
    const konetBal = await blockDataUtill.konetBal( address);
    if( stakingBalval == ""){
      alert('Please enter the number of staked items.');
      return;
    }
 
    if ( Number( stakingBalval) > konetBal){
      alert('You do not have enough konet.');
      return;

    }


    await blockDataUtill.konetStaking( address, ( "" + stakingBalval), "0x0000000000000000000000000000000000000000");
    window.location.reload();
    
  }


  const handleMessageFromChild = (newAddress) => {
    setAddress(newAddress);  // 자식에서 받은 메시지로 상태 업데이트
  };

  const WithDrawFn = async () => {
    $( ".withDrawBack").show();
    $( ".withDrawBody").show();

  }

  const fullTwoDataMinForm = (date) => {
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();

    month = month >= 10 ? month : '0' + month;
    day = day >= 10 ? day : '0' + day;
    hour = hour >= 10 ? hour : '0' + hour;
    minute = minute >= 10 ? minute : '0' + minute;
    second = second >= 10 ? second : '0' + second;

    const fullYear = date.getFullYear(); // 예: 2024


    return fullYear +"-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
  }

  const withDrawContCall = async () => {
    //const
    const whDrawBal = $( "#withdrawInp").val();
    if(whDrawBal == ""){
      alert('Please enter the number of withdrawals.');
      return;
    }

    const rockUp = await blockDataUtill.withDrawRockUpCheck( address);


    const rockUpDate = new Date(Number( rockUp[0]) * 1000); 
    const rockUpDateNum = Number( fullTwoDataMinUnForm( rockUpDate));
    const rockUpDateForm =  fullTwoDataMinForm( rockUpDate);

    
    const nowUpDate = new Date(Number( rockUp[1]) * 1000); 
    const nowUpDateNum = Number( fullTwoDataMinUnForm( nowUpDate));
    
    if( rockUpDateNum>nowUpDateNum){
      alert('It is currently locked up because of the vote. unLock-date : ' +rockUpDateForm );
      return;

    }

    const memberInfoVal = await blockDataUtill.getStakingBal( address);
    const balance = await blockDataUtill.balanceOfFomatTwo( memberInfoVal);



    if( Number(whDrawBal) > balance){
      alert('he requested amount of staking konet is insufficient.');
      return;    
    }


    await blockDataUtill.withDraw(( "" + whDrawBal));

    initView( address);

    
  }

  //

  const withDrawNone = () =>{
    $( ".withDrawBack").hide();
    $( ".withDrawBody").hide();
  }

  useEffect(() => {

      return () => {
          console.log('컴포넌트가 언마운트될 때 실행됩니다.');
      };
  }, []); 
  useEffect(() => {
    if( address != null && address != ""){
      initView( address);
    }
    return () => {
        console.log('컴포넌트가 언마운트될 때 실행됩니다.');
    };
  }, [address]); 

  //



  return (
    <div className="HomeLst">
        <TopMenu menuType={1} handle={handleMessageFromChild} />

        <div className=''></div>
        
        <div className='withDrawBack' onClick={(e)=>{withDrawNone()}}></div>
        <div className='withDrawBody'>
            <div className='withDrawTtl'>WithDraw</div>
            <div className='withDrawSubTtl'>My staked amount</div>
            <div className='withDrawBal'><span className='withDrawBalBorder'>{statkingBalOf}</span> KONET</div>
            <div className='widthInpHei'></div>
            <div className='inpRap'>
                <input type="number" id='withdrawInp' />
            </div>
            <div className='withBtnRap'>
              <div className='withLtBtn' onClick={(e)=>{withDrawNone()}}>close</div>
              <div className='withRtBtn bg_black_000000 fc_FFFFFF' onClick={(e)=>{withDrawContCall()}}>WithDraw</div>
              <div style={{clear : "both"}}></div>
            </div>
        </div>

        <div className='maxWidthRap tpMarHei'>
            <div className='konetStakingTpLtRap'>
              <img src={process.env.PUBLIC_URL + "/imgaes/stakingMainimage.png"} />
            </div>
            <div className='konetStakingTpRtRap'>
              <div className='ksTpRtTotTtl fc_000000'>Earn Rewards with KONET Staking on the Mainnet</div>
              <div className='ksTpRtTotSubTtl fc_A6A6A6'>Stake your KONET Coins and enjoy an annual percentage rate (APR) of 3% to over 10%. Start now and watch your rewards grow daily!</div>
              <div className='ksTpRtTotRap bg_blue_FFFFFF'>

                <div className='kstrt_Ttl fc_000000'>Network</div>
                <div className='kstrt_Sub'>
                    <input type="text" value={"KONET"} className='kstrtInput' />

                </div>

                <div className='kstrt_Ttl kstrt_Ttl_tp_mar fc_000000'>My balance</div>
                <div className='kstrt_Sub'>
                    <input type="text" value={konetBalOf} className='kstrtInput'  />

                </div>

                <div className='kstrt_Ttl kstrt_Ttl_tp_mar fc_000000'>Staking amount</div>
                <div className='kstrt_Sub'>
                    <input type="number" id='stakingInp' className='kstrtInput' placeholder='Enter staking amount' />

                </div>

                {/* <div className='kstrt_Ttl kstrt_Ttl_tp_mar fc_FFFFFF'>APR</div>
                <div className='kstrt_Sub'>
                  <div className='kstrt_per fc_00FF33'>5%</div>
                </div> */}

                <div className='stakingButton bg_blue_C4CFFF fc_0029E2' onClick={(e)=>{stakingCall()}}>Stake</div>
              </div>


            </div>
            <div style={{clear : "both"}}></div>
        </div>
        {/* 5B6BAF */}

        <div className='maxWidthRap meMarHei'>
            {/* <div className='meTpTtl fc_FFFFFF'>Stake your KONET and earn rewards.</div>
            <div className='meMeRap meMetableMar bg_blue_0b1830'>
              <table border="0" className='meMeTable' width="100%">
                <tr>
                  <td className='meMeTableTd meMeTableTdBorder fc_FFFFFF' width="260">Staked amount</td>
                  <td className='meMeTableTd meMeTableTdBorder fc_FFFFFF'>Network</td>
                  <td className='meMeTableTd meMeTableTdBorder fc_FFFFFF'>Lock-up Period</td>
                  <td className='meMeTableTd meMeTableTdBorder fc_FFFFFF'>Rewards Frequency</td>
                  <td className='meMeTableTd meMeTableTdBorder fc_FFFFFF' width="320">APR</td>
                </tr>
                
                <tr>
                  <td className='meMeTableTd  fc_FFFFFF'>1 KONET~</td>
                  <td className='meMeTableTd  fc_FFFFFF'>KONET</td>
                  <td className='meMeTableTd  fc_FFFFFF'>No lock-up</td>
                  <td className='meMeTableTd  fc_FFFFFF'>Daily</td>
                  <td className='meMeTableTd  fc_FFFFFF'>
                    <div className='meMeTableTdAprBtn fc_00FF33 bg_black_000000'>3%</div>

                  </td>
                </tr>

                <tr>
                  <td className='meMeTableTd  fc_FFFFFF'>30,000 KONET~</td>
                  <td className='meMeTableTd  fc_FFFFFF'>KONET</td>
                  <td className='meMeTableTd  fc_FFFFFF'>No lock-up</td>
                  <td className='meMeTableTd  fc_FFFFFF'>Daily</td>
                  <td className='meMeTableTd  fc_FFFFFF'>
                    <div className='meMeTableTdAprBtn fc_00FF33 bg_black_000000'>4%</div>

                  </td>
                </tr>

                <tr>
                  <td className='meMeTableTd  fc_FFFFFF'>200,000 KONET~</td>
                  <td className='meMeTableTd  fc_FFFFFF'>KONET</td>
                  <td className='meMeTableTd  fc_FFFFFF'>No lock-up</td>
                  <td className='meMeTableTd  fc_FFFFFF'>Daily</td>
                  <td className='meMeTableTd  fc_FFFFFF'>
                    <div className='meMeTableTdAprBtn fc_00FF33 bg_black_000000'>5%</div>

                  </td>
                </tr>
              </table>
            </div> */}


            {/* <div className='meTxtRap'>
              <table border="0" >
                <tr>
                  <td className='fc_A6A6A6 jumFontResize' valign='top'>&#8226;</td>
                  <td className='fc_A6A6A6 bonTxtCall'>Staking is a service where you use KONET Coin to earn annual percentage rate (APR) rewards based on the amount staked.
                  The first reward is distributed 24 hours after the participation time.</td>
                </tr>
                <tr>
                  <td className='fc_A6A6A6 jumFontResize' valign='top'>&#8226;</td>
                  <td className='fc_A6A6A6 bonTxtCall'>
                  Rewards are distributed every 24 hours. If you unstake during the staking period, the next reward will not be given.
                  You can freely participate in and withdraw from KONET staking.
                  </td>
                </tr>
                <tr>
                  <td className='fc_A6A6A6 jumFontResize' valign='top'>&#8226;</td>
                  <td className='fc_A6A6A6 bonTxtCall'>
                  Before staking KONET, please verify that you are using the official site.
                  </td>
                </tr>
                <tr>
                  <td className='fc_A6A6A6 jumFontResize' valign='top'>&#8226;</td>
                  <td className='fc_A6A6A6 bonTxtCall'>
                  Ensure you understand any network fees that may arise during the staking process before participating.
                  </td>
                </tr>
                <tr>
                  <td className='fc_A6A6A6 jumFontResize' valign='top'>&#8226;</td>
                  <td className='fc_A6A6A6 bonTxtCall'>
                  Make sure you fully understand the terms, duration, and rewards of the staking product before participating.
                  </td>
                </tr>
                <tr>
                  <td className='fc_A6A6A6 jumFontResize' valign='top'>&#8226;</td>
                  <td className='fc_A6A6A6 bonTxtCall'>
                  Participation in staking is at your own discretion, and you are solely responsible for any consequences. Please carefully review and make informed decisions before proceeding with staking.
                  </td>
                </tr>

              </table>
            </div> */}

            <div className='meTpTtl meTwoMar fc_000000' style={{textAlign : 'center'}}>My Staked</div>
  
            <div className='myStakeView bg_blue_FFFFFF'>
              <table border="0" className='meMeTable' width="100%" style={{minWidth : "100%"}}>
                <tr>
                 
                  <td className='meMeTableTd meMeTableTdBorder fc_000000' style={{borderBottom : "0px"}}>Token name</td>
                  <td className='meMeTableTd meMeTableTdBorder fc_000000' style={{borderBottom : "0px"}}>APR</td>
                  {/* <td className='meMeTableTd meMeTableTdBorder fc_FFFFFF'>My staked amount</td>
                  <td className='meMeTableTd meMeTableTdBorder fc_FFFFFF'>Rewards frequency</td>
                  <td className='meMeTableTd meMeTableTdBorder fc_FFFFFF'>Staking start date</td> */}
                </tr>
                <tr>
                  
                  <td className='meMeTableTd  fc_000000'>
                    <div className='tokenSymbolRap'>
                      <div className="tokenSymbolLt">
                        <img src={process.env.PUBLIC_URL + "/imgaes/konetWhiteSymbol.png"} />
                      </div>
                      <div className='tokenTxtRt'>KONET</div>
                      <div style={{clear : "both"}}></div>

                    </div>
                    
                  </td>
                  <td className='meMeTableTd  fc_00FF33'>{stakingFeeCal}%</td>
                  {/* <td className='meMeTableTd meMeTableTdBorder fc_FFFFFF'>2,340 KONET</td>
                  <td className='meMeTableTd  fc_FFFFFF'>Daily</td>
                  <td className='meMeTableTd  fc_FFFFFF'>2024-02-04 06:20:34</td> */}
                </tr>

                <tr>
                  <td className='meMeTableTd meMeTableTdBorder fc_000000' colSpan="2" style={{borderBottom : "0px"}}>My staked amount</td>
                </tr>

                <tr>
                  <td className='meMeTableTd fc_000000' colSpan="2">{statkingBalOf} KONET</td>
                </tr>

                <tr>
                  <td className='meMeTableTd meMeTableTdBorder fc_000000' colSpan="2" style={{borderBottom : "0px"}}>vKonet amount</td>
                </tr>

                <tr>
                  <td className='meMeTableTd fc_000000' colSpan="2">{vKonetBalOf} vkonet</td>
                </tr>


                <tr>
                  <td className='meMeTableTd meMeTableTdBorder fc_000000' style={{borderBottom : "0px"}}>Rewards frequency</td>
                  <td className='meMeTableTd meMeTableTdBorder fc_000000' style={{borderBottom : "0px"}}>Staking Last date</td> 
                </tr>

                <tr>
                  <td className='meMeTableTd  fc_000000'>Daily</td>
                  <td className='meMeTableTd  fc_000000'>{stakingFinalDate}</td>
                </tr>

              </table> 

            </div>

            <div className='myStakeView' style={{padding : "0px"}}>
            <div className='stakingButton bg_blue_C4CFFF fc_0029E2' onClick={(e)=>{WithDrawFn()}}>WithDraw</div>

            </div>
            


            {/* <div className='meMeRap meMetableMar bg_blue_0b1830'>
                <div>1</div>

              <table border="0" className='meMeTable' width="100%">
                <tr>
                 
                  <td className='meMeTableTd meMeTableTdBorder fc_FFFFFF'>Staking name</td>
                  <td className='meMeTableTd meMeTableTdBorder fc_FFFFFF'  width="50">APR</td>
                  <td className='meMeTableTd meMeTableTdBorder fc_FFFFFF'>My staked amount</td>
                  <td className='meMeTableTd meMeTableTdBorder fc_FFFFFF'>Rewards frequency</td>
                  <td className='meMeTableTd meMeTableTdBorder fc_FFFFFF'>Staking start date</td>
                </tr>
                <tr>
                  
                  <td className='meMeTableTd  fc_FFFFFF'>
                    <div className='tokenSymbolRap'>
                      <div className="tokenSymbolLt">
                        <img src={process.env.PUBLIC_URL + "/imgaes/konetWhiteSymbol.png"} />
                      </div>
                      <div className='tokenTxtRt'>KONET Staking</div>
                      <div style={{clear : "both"}}></div>

                    </div>
                    
                  </td>
                  <td className='meMeTableTd  fc_00FF33'>3%</td>
                  <td className='meMeTableTd meMeTableTdBorder fc_FFFFFF'>2,340 KONET</td>
                  <td className='meMeTableTd  fc_FFFFFF'>Daily</td>
                  <td className='meMeTableTd  fc_FFFFFF'>2024-02-04 06:20:34</td>
                </tr>
              </table> 
            </div>  */}


            {/* <div className='meTpTtl meTwoMar fc_FFFFFF'>Transaction</div>
            <div className='meMeRap meMetableMar'>
              <table border="0" className='meMeTable' width="100%">
                <tr>
                  <td className='meMeTableTdTwo meMeTableTdBorder fc_FFFFFF' width="50">No</td>
                  <td className='meMeTableTdTwo meMeTableTdBorder fc_FFFFFF'>Amount</td>
                  <td className='meMeTableTdTwo meMeTableTdBorder fc_FFFFFF'>TXID</td>
                  <td className='meMeTableTdTwo meMeTableTdBorder fc_FFFFFF'>Date</td>
                </tr>
           
                <tr>
                  <td className='meMeTableTdTwo  fc_FFFFFF' >01</td>
                  <td className='meMeTableTdTwo  fc_FFFFFF'>1234.4567 KONET</td>
                  <td className='meMeTableTdTwo  fc_FFFFFF'>ox15dbggadf43sasdggdda12532ox15dbggadf43sasdggdda12532ox15dbggadf43sasdggdda12532</td>
                  <td className='meMeTableTdTwo  fc_FFFFFF'>2024-01-12 12:34:56</td>
                </tr>

              </table>


            </div> */}


        </div>

        <div className='bottomHeiRap'></div>

    </div>
  );
};

export default Home;